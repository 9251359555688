/* eslint-disable no-undef */

const UTM_CAMPAIGN = NEXT_PUBLIC_BUILD_TYPE ?? 'rv'

const PARTNERS_URL_PARAMETERS = `utm_source=cross-sell&utm_campaign=${UTM_CAMPAIGN}&utm_medium=equifax`

export const REDIRECT_TO_LOGIN =
  'https://transacional.bvsnet.com.br/login.php?utm_source=lp_rv&utm_medium=fx_aquisicao'

export const CLIENT_PORTAL_URL = 'https://transacional.bvsnet.com.br/login.php'

export const REPRESENTATIVE_URL =
  'https://cloud.mkt.boavistaservicos.com.br/representante-autorizado-equifax-boavista'

export const POSITIVE_SUBSCRIPTION_URL =
  'https://consumidor.boavistaservicos.com.br/cadastro-positivo/'

export const CUSTOMER_SERVICE_URL =
  'https://painel-consumidor.boavistaservicos.com.br/atendimento'

export const SAC_CHAT_URL =
  'https://dw-api.bvsnet.com.br/formulario/Transacional'

export const PRIVACY_POLITIC = `${urlEquifaxOrganic}/termo-de-uso-e-politica-de-privacidade/`

export const POSITIVE_CONSUMER_URL = `https://www.consumidorpositivo.com.br/?${PARTNERS_URL_PARAMETERS}`

export const GET_CREDIT_CARD_URL = `https://www.consumidorpositivo.com.br/cartao-de-credito/?${PARTNERS_URL_PARAMETERS}`

export const RIGHT_DEAL_URL = `https://www.acordocerto.com.br/?${PARTNERS_URL_PARAMETERS}`

export const KONDUTO_URL = 'https://www.konduto.com/'

export const BLUEBOX_URL = `${urlEquifaxOrganic}/blue-box/`
