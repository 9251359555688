'use client'

import { If, SkeletonLoader, Softlead } from '@/components'

import { StepError } from '..'

import { useProposal } from './useProposal'

import styles from './Proposal.module.scss'

export const Proposal = () => {
  const { isLoading, hasError, setHasError, onCatch } = useProposal()

  return (
    <div id="proposal" className={styles.proposal}>
      <If
        condition={isLoading}
        renderIf={<SkeletonLoader.Step />}
        renderElse={
          hasError ? (
            <StepError setHasError={setHasError} />
          ) : (
            <Softlead
              origin="hero"
              topContent={
                <h2 className={styles.proposal__title}>
                  Contrate seu plano Pós-pago
                </h2>
              }
              onCatch={onCatch}
            />
          )
        }
      />
    </div>
  )
}
