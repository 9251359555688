import {
  AFFILIATE_PLAN,
  CUSTOM_PLAN,
  POST_PAID_PLANS,
  PRE_PAID_PLANS
} from '@/constants/plans'

import { useConditionalRendering } from './useConditionalRendering'

export const usePlans = () => {
  const { shouldRenderUnaffiliate } = useConditionalRendering()

  const customPlan = shouldRenderUnaffiliate ? CUSTOM_PLAN : AFFILIATE_PLAN
  const postpaidPlans = [...POST_PAID_PLANS, customPlan]

  return {
    postpaidPlans,
    prepaidPlans: PRE_PAID_PLANS
  }
}
