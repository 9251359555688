'use client'

import {
  ButtonWhatsapp,
  Card,
  HighlightTitle,
  Input,
  List,
  Modal
} from '@/components'

import _validators from '@/utils/validators'

import { POSITIVE_CONSUMER_URL, RIGHT_DEAL_URL } from '@/constants/links'
import { CONSUMER_PHONE_NUMBER, SAC_PHONE_NUMBER } from '@/constants/phones'

import { usePaywall } from './usePaywall'

import styles from './Paywall.module.scss'

const cnpjValidation = {
  required: 'Campo obrigatório',
  validate: (value) => _validators.cnpj(value) || 'CNPJ inválido'
}

export const Paywall = () => {
  const { control, isValid, onClickWhatsapp, closeModal } = usePaywall()

  return (
    <Modal
      section="paywall"
      title="Para continuar informe o CNPJ da sua empresa"
      onClose={closeModal}
    >
      <form className={styles.paywall__form}>
        <Input.Cnpj
          id="cnpj"
          name="cnpj"
          label="CNPJ da minha empresa"
          placeholder="XX.XXX.XXX/0001-XX"
          control={control}
          rules={cnpjValidation}
        />

        <ButtonWhatsapp
          hasIcon={false}
          disabled={!isValid}
          id="paywall-whatsapp-button"
          aria-label="Falar com especialista"
          onClickWhatsapp={onClickWhatsapp}
        >
          Contratar pelo WhatsApp
        </ButtonWhatsapp>
      </form>

      <Card
        withBorder
        size="small"
        bgColor="gray"
        customClass={styles.paywall__details}
      >
        <HighlightTitle
          size="small"
          icon="equifax-alert"
          text={<b>Este é um canal exclusivo para vendas.</b>}
        />

        <List
          customClass={styles.paywall__list}
          items={[
            <>
              Quer contestar ou <b>negociar débitos no seu CNPJ</b>? Procure a
              empresa que realizou a negativação.
            </>,
            <>
              Quer contestar ou <b>negociar débitos no seu CPF</b>?{' '}
              <a
                target="_blank"
                rel="noreferrer"
                id="paywall-negotiateDebts-link--url"
                href={`${RIGHT_DEAL_URL}&utm_placement=paywall`}
                onClick={closeModal}
              >
                Clique aqui
              </a>
              {' ou ligue '}
              <a
                target="_blank"
                rel="noreferrer"
                id="paywall-negotiateDebts-link--phone"
                href={`tel:${CONSUMER_PHONE_NUMBER}`}
                onClick={closeModal}
              >
                {CONSUMER_PHONE_NUMBER}
              </a>
            </>,
            <>
              Não tem CNPJ e quer <b>consultar seu CPF</b>?{' '}
              <a
                target="_blank"
                rel="noreferrer"
                id="paywall-positiveConsumer-link"
                href={`${POSITIVE_CONSUMER_URL}&utm_placement=paywall`}
                onClick={closeModal}
              >
                Clique aqui
              </a>
            </>,
            <>
              Já é cliente? Fale com o SAC:{' '}
              <a
                target="_blank"
                rel="noreferrer"
                id="paywall-sac-link"
                href={`tel:${SAC_PHONE_NUMBER}`}
                onClick={closeModal}
              >
                {SAC_PHONE_NUMBER}
              </a>
            </>
          ]}
        />
      </Card>
    </Modal>
  )
}
