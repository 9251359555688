import { Card, HighlightTitle } from '@/components'

import { Actions } from '..'

import styles from './Service.module.scss'

export const Highlights = ({ highlights }) => {
  return (
    <div>
      {highlights.map((text, index) => (
        <HighlightTitle
          size="medium"
          icon="equifax-check-circle"
          text={text}
          key={`solution-highlight-${index}`}
          customClass={styles.service__highlight}
        />
      ))}
    </div>
  )
}

export const Service = ({
  variant,
  isAnimated,
  visibleService,
  onClickAction
}) => {
  return (
    <div
      className={`${styles.service} ${styles[`service--${variant}`]} ${isAnimated && styles['service--animated']}`}
    >
      <h4 className={`${styles.service__title}`}>{visibleService.title}</h4>

      {visibleService.highlights && variant === 'postpaid' && (
        <Highlights highlights={visibleService.highlights} />
      )}

      <Card size="large" bgColor="red">
        {visibleService.description}
      </Card>

      {visibleService.highlights && variant === 'home' && (
        <Highlights highlights={visibleService.highlights} />
      )}

      {visibleService.details.map(({ icon, title, content }, index) => (
        <Card
          size="large"
          customClass={styles.service__infos}
          key={`solution-detail-${index}`}
        >
          {title && (
            <HighlightTitle size="medium" icon={icon} text={<b>{title}</b>} />
          )}

          <div className={styles.service__details}>{content}</div>
        </Card>
      ))}

      {visibleService.actions_type && (
        <Actions type={visibleService.actions_type} onClick={onClickAction} />
      )}
    </div>
  )
}
