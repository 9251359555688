'use client'

import { useEffect } from 'react'
import { useRouter } from 'next/navigation'
import { useForm } from 'react-hook-form'

import { useExperiment } from 'rvbr-www/red-testing'

import { removeWhiteSpaces } from '@equifax-ui/utils/formatters/removeWhiteSpaces'
import removeSpecialChars from '@equifax-ui/utils/formatters/removeSpecialChars'
import isClient from '@equifax-ui/utils/browser/isClient'

import _validators from '@/utils/validators'

import { createLead } from '@/services/steps'

import { useProposalContext } from '@/context/ProposalContext'

import { useAnalytics } from '@/hooks/useAnalytics'
import { useStepHandler } from '@/hooks/useStepHandler'
import { triggerTracrTrack } from '@/hooks/useTracking'

import { SOFTLEAD_LABEL, TOKEN_LABEL } from '@/constants'
import { STEPS } from '@/constants/steps'

export const useSoftlead = ({ basePayload, onCatch }) => {
  const { leadData, isLoading, setIsLoading, setLeadData } =
    useProposalContext()

  const { variant, successfulExperiment } = useExperiment('form_v2')
  const { onFormFinish } = useStepHandler()
  const { getTrackingData } = useAnalytics()

  const router = useRouter()
  const softleadData = isClient() && sessionStorage.getItem(SOFTLEAD_LABEL)

  const {
    control,
    formState: { isValid },
    getValues,
    handleSubmit,
    reset
  } = useForm({
    mode: 'onChange'
  })

  useEffect(() => {
    reset(leadData)
    setIsLoading(false)
  }, [leadData])

  useEffect(() => {
    const parsedSoftleadData = JSON.parse(softleadData) || {}

    reset({ ...getValues(), ...parsedSoftleadData })
  }, [softleadData])

  const onBlurField = ({ target: { name, value } }) => {
    if (_validators[name](value)) {
      const softleadData = sessionStorage.getItem(SOFTLEAD_LABEL)
      const parsedSoftleadData = JSON.parse(softleadData) || {}

      const newSoftleadData = { ...parsedSoftleadData, [name]: value }

      sessionStorage.setItem(SOFTLEAD_LABEL, JSON.stringify(newSoftleadData))

      if (name === 'email') triggerTracrTrack('formInteracted', getValues())
    }
  }

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      ...basePayload,
      cnpj: removeSpecialChars(data.cnpj),
      phone: removeWhiteSpaces(removeSpecialChars(data.phone))
    }

    createSoftlead(payload)
  }

  const createSoftlead = async (payload) => {
    setIsLoading(true)

    try {
      const flow = variant?.id === 'b' ? '?flow-key=flow-post-paid-a' : ''
      const trackingData = await getTrackingData()

      const {
        data: { next_step = 'softlead', form_finished = false, auth, data }
      } = await createLead(
        {
          ...payload,
          analytics: trackingData
        },
        flow
      )

      setLeadData((prev) => ({ ...prev, ...data }))
      successfulExperiment()

      if (auth) sessionStorage.setItem(TOKEN_LABEL, JSON.stringify(auth))
      if (form_finished) onFormFinish()

      router.push(`/formulario/${STEPS[next_step]}`)
    } catch (err) {
      onCatch && onCatch()
      setIsLoading(false)
    }
  }

  return {
    control,
    variant,
    isValid,
    isLoading,
    onBlurField,
    handleSubmit,
    onSubmit
  }
}
