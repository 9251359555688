import {
  WHATSAPP_BASE_URL,
  WHATSAPP_PHONE_NUMBERS,
  WHATSAPP_URL_PARAMS
} from './whatsapp'

export const CUSTOM_PLAN = {
  id: 'custom',
  type: 'postpaid',
  is_best_selling: false,
  name: 'Avançado',
  label: 'Plano Avançado',
  highlight_text: (
    <>
      <b>Personalizado</b>
    </>
  ),
  text: (
    <>
      Oferecemos planos exclusivos para atender às necessidades da sua empresa.
    </>
  ),
  url: `${WHATSAPP_BASE_URL}${WHATSAPP_PHONE_NUMBERS['organic']}${WHATSAPP_URL_PARAMS}`
}

export const AFFILIATE_PLAN = {
  id: 'affiliate',
  type: 'postpaid',
  is_recommended: false,
  name: 'Master',
  label: 'Plano Master',
  slug_name: 'master',
  consultations_per_month: '80',
  cnpj_consultation_min_value: 'R$ 6,89',
  monthly_price: 'R$ 600,00',
  discount: '33%',
  highlight_text: (
    <>
      <b>R$ 600,00</b>
    </>
  ),
  text: (
    <>
      Consultas de CNPJ a partir de <b>R$ 6,89</b>
      <br />
      Consultas de CPF a partir de <b>R$ 8,61</b>
    </>
  ),
  details: [
    [
      { name: 'Consulta por CPF', value: 'Valor' },
      { name: 'Acerta Essencial', value: 'R$ 8,61' },
      { name: 'Acerta Mais', value: 'R$ 12,89' },
      { name: 'Acerta Completo', value: 'R$ 16,20' }
    ],
    [
      { name: 'Consulta por CNPJ', value: 'Valor' },
      { name: 'Define Risco', value: 'R$ 6,89' },
      { name: 'Define Negócio', value: 'R$ 16,20' },
      { name: 'Define  Limite', value: 'R$ 24,30' }
    ],
    [
      { name: 'Notificação e Negativação', value: 'Valor' },
      { name: 'Aviso eletrônico de débito', value: 'R$ 3,19' },
      { name: 'Carta + Negativação', value: 'R$ 9,56' }
    ],
    [
      { name: 'InterConnect Box Aprova', value: 'Valor' },
      { name: 'Valor fixo', value: 'R$ 2,95' }
    ]
  ]
}

export const PRE_PAID_PLANS = [
  {
    id: 'pre-1',
    type: 'prepaid',
    highlight_text: <b>R$ 40</b>,
    text: (
      <>
        Consultas a partir de <b>R$ 10,40</b>
      </>
    ),

    label: 'Recarga',
    url: 'https://loja.boavistaservicos.com.br/?add-to-cart=75'
  },
  {
    id: 'pre-2',
    type: 'prepaid',
    highlight_text: <b>R$ 70</b>,
    text: (
      <>
        Consultas a partir de <b>R$ 10,40</b>
      </>
    ),
    label: 'Recarga',
    url: 'https://loja.boavistaservicos.com.br/?add-to-cart=76'
  },
  {
    id: 'pre-3',
    type: 'prepaid',
    highlight_text: <b>R$ 130</b>,
    text: (
      <>
        Consultas a partir de <b>R$ 10,40</b>
      </>
    ),
    label: 'Recarga',
    url: 'https://loja.boavistaservicos.com.br/?add-to-cart=77'
  },
  {
    id: 'pre-4',
    type: 'prepaid',
    highlight_text: <b>R$ 250</b>,
    text: (
      <>
        Consultas a partir de <b>R$ 10,40</b>
      </>
    ),
    label: 'Recarga',
    url: 'https://loja.boavistaservicos.com.br/?add-to-cart=78'
  },
  {
    id: 'pre-5',
    type: 'prepaid',
    highlight_text: <b>R$ 500</b>,
    text: (
      <>
        Consultas a partir de <b>R$ 10,40</b>
      </>
    ),
    label: 'Recarga',
    url: 'https://loja.boavistaservicos.com.br/?add-to-cart=205'
  }
]

export const POST_PAID_PLANS = [
  {
    id: 'basic',
    type: 'postpaid',
    is_best_selling: false,
    name: 'Econômico',
    label: 'Plano Econômico',
    slug_name: 'economico',
    consultations_per_month: '10',
    cnpj_consultation_min_value: 'R$ 10,29',
    monthly_price: 'R$ 99,90',
    highlight_text: (
      <>
        <b>R$ 99,90</b>
      </>
    ),
    text: (
      <>
        Consultas de CNPJ a partir de <b>R$ 10,29</b>
        <br />
        Consultas de CPF a partir de <b>R$ 12,86</b>
      </>
    ),
    details: [
      [
        { name: 'Consulta por CPF', value: 'Valor' },
        { name: 'Acerta Essencial', value: 'R$ 12,86' },
        { name: 'Acerta Mais', value: 'R$ 19,65' },
        { name: 'Acerta Completo', value: 'R$ 24,20' }
      ],
      [
        { name: 'Consulta por CNPJ', value: 'Valor' },
        { name: 'Define Risco', value: 'R$ 10,29' },
        { name: 'Define Negócio', value: 'R$ 24,20' },
        { name: 'Define  Limite', value: 'R$ 36,30' }
      ],
      [
        { name: 'Notificação e Negativação', value: 'Valor' },
        { name: 'Aviso eletrônico de débito', value: 'R$ 3,61' },
        { name: 'Carta + Negativação', value: 'R$ 10,80' }
      ],
      [
        { name: 'InterConnect Box Aprova', value: 'Valor' },
        { name: 'Valor fixo', value: 'R$ 2,95' }
      ]
    ]
  },
  {
    id: 'intermediate',
    type: 'postpaid',
    is_best_selling: false,
    name: 'Mais',
    label: 'Plano Mais',
    slug_name: 'mais',
    consultations_per_month: '15',
    cnpj_consultation_min_value: 'R$ 9,35',
    monthly_price: 'R$ 149,90',
    discount: '9%',
    highlight_text: (
      <>
        <b>R$ 149,90</b>
      </>
    ),
    text: (
      <>
        Consultas de CNPJ a partir de <b>R$ 9,35</b>
        <br />
        Consultas de CPF a partir de <b>R$ 11,69</b>
      </>
    ),
    details: [
      [
        { name: 'Consulta por CPF', value: 'Valor' },
        { name: 'Acerta Essencial', value: 'R$ 11,69' },
        { name: 'Acerta Mais', value: 'R$ 17,68' },
        { name: 'Acerta Completo', value: 'R$ 22,00' }
      ],
      [
        { name: 'Consulta por CNPJ', value: 'Valor' },
        { name: 'Define Risco', value: 'R$ 9,35' },
        { name: 'Define Negócio', value: 'R$ 22,00' },
        { name: 'Define  Limite', value: 'R$ 33,00' }
      ],
      [
        { name: 'Notificação e Negativação', value: 'Valor' },
        { name: 'Aviso eletrônico de débito', value: 'R$ 3,50' },
        { name: 'Carta + Negativação', value: 'R$ 10,47' }
      ],
      [
        { name: 'InterConnect Box Aprova', value: 'Valor' },
        { name: 'Valor fixo', value: 'R$ 2,95' }
      ]
    ]
  },
  {
    id: 'advanced',
    type: 'postpaid',
    is_best_selling: true,
    name: 'Elite',
    label: 'Plano Elite',
    slug_name: 'elite',
    consultations_per_month: '30',
    cnpj_consultation_min_value: 'R$ 8,50',
    monthly_price: 'R$ 249,90',
    discount: '17%',
    highlight_text: (
      <>
        <b>R$ 249,90</b>
      </>
    ),
    text: (
      <>
        Consultas de CNPJ a partir de <b>R$ 8,50</b>
        <br />
        Consultas de CPF a partir de <b>R$ 10,63</b>
      </>
    ),
    details: [
      [
        { name: 'Consulta por CPF', value: 'Valor' },
        { name: 'Acerta Essencial', value: 'R$ 10,63' },
        { name: 'Acerta Mais', value: 'R$ 15,91' },
        { name: 'Acerta Completo', value: 'R$ 20,00' }
      ],
      [
        { name: 'Consulta por CNPJ', value: 'Valor' },
        { name: 'Define Risco', value: 'R$ 8,50' },
        { name: 'Define Negócio', value: 'R$ 20,00' },
        { name: 'Define  Limite', value: 'R$ 30,00' }
      ],
      [
        { name: 'Notificação e Negativação', value: 'Valor' },
        { name: 'Aviso eletrônico de débito', value: 'R$ 3,40' },
        { name: 'Carta + Negativação', value: 'R$ 10,16' }
      ],
      [
        { name: 'InterConnect Box Aprova', value: 'Valor' },
        { name: 'Valor fixo', value: 'R$ 2,95' }
      ]
    ]
  },
  {
    id: 'premium',
    type: 'postpaid',
    is_best_selling: false,
    name: 'Exclusivo',
    label: 'Plano Exclusivo',
    slug_name: 'exclusivo',
    consultations_per_month: '50',
    cnpj_consultation_min_value: 'R$ 7,65',
    monthly_price: 'R$ 399,00',
    discount: '25%',
    highlight_text: (
      <>
        <b>R$ 399,00</b>
      </>
    ),
    text: (
      <>
        Consultas de CNPJ a partir de <b>R$ 7,65</b>
        <br />
        Consultas de CPF a partir de <b>R$ 9,56</b>
      </>
    ),
    details: [
      [
        { name: 'Consulta por CPF', value: 'Valor' },
        { name: 'Acerta Essencial', value: 'R$ 9,56' },
        { name: 'Acerta Mais', value: 'R$ 14,32' },
        { name: 'Acerta Completo', value: 'R$ 18,00' }
      ],
      [
        { name: 'Consulta por CNPJ', value: 'Valor' },
        { name: 'Define Risco', value: 'R$ 7,65' },
        { name: 'Define Negócio', value: 'R$ 18,00' },
        { name: 'Define  Limite', value: 'R$ 27,00' }
      ],
      [
        { name: 'Notificação e Negativação', value: 'Valor' },
        { name: 'Aviso eletrônico de débito', value: 'R$ 3,29' },
        { name: 'Carta + Negativação', value: 'R$ 9,85' }
      ],
      [
        { name: 'InterConnect Box Aprova', value: 'Valor' },
        { name: 'Valor fixo', value: 'R$ 2,95' }
      ]
    ]
  }
]

export const PLANS_DATA = { prepaid: PRE_PAID_PLANS, postpaid: POST_PAID_PLANS }
