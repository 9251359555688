'use client'

import { useEffect, useRef } from 'react'

import { Icon } from '..'

import styles from './Modal.module.scss'

export const Modal = ({
  color,
  children,
  customClass,
  title,
  section,
  isAnimated,
  onClose
}) => {
  const ref = useRef()

  useEffect(() => {
    ref.current?.showModal()
    return () => ref.current?.close()
  }, [])

  return (
    <dialog
      ref={ref}
      data-overlay={true}
      onCancel={onClose}
      className={`
          ${styles.modal}
          ${styles[`modal--${color}`]}
          ${isAnimated && styles['modal--animated']}
          ${customClass}
      `}
    >
      {(title || onClose) && (
        <div className={styles.modal__header}>
          {title && <p className={styles.modal__title}>{title}</p>}

          {onClose && (
            <button
              onClick={onClose}
              aria-label="Fechar o modal"
              id={`${section}-close-button`}
              className={styles.modal__closeButton}
            >
              <Icon name="equifax-close" size="small" />
            </button>
          )}
        </div>
      )}

      {children}
    </dialog>
  )
}
