export const HERO_TEXTS = {
  home: {
    title: 'Somos a Equifax | BoaVista',
    subtitle:
      'Uma empresa global que oferece dados para análise de crédito de pessoas e empresas.'
  },
  organic: {
    title: 'Contrate agora mesmo um plano Pós-pago!',
    subtitle:
      'Com nosso plano pós-pago, você acessa dados atualizados sobre seus clientes, garantindo decisões mais seguras e assertivas para o seu negócio.'
  },
  paid: {
    title: 'Evite surpresas!',
    subtitle:
      'Consulte CNPJ e CPF dos seus clientes e saiba com quem está negociando.'
  }
}

export const ANCHORS = [
  {
    icon: '',
    label: 'Conhecer',
    text: 'Pós-pago',
    id: 'hero-card-postpaid',
    url: '#'
  },
  {
    icon: '',
    label: 'Conhecer',
    text: 'Pré-pago',
    id: 'hero-card-prepaid',
    url: '#'
  },
  {
    icon: '',
    label: 'Conhecer',
    text: 'Antifraude',
    id: 'hero-card-antifraud',
    url: '#'
  },
  {
    icon: '',
    label: 'Consultar',
    text: 'Meu CPF',
    id: 'hero-card-cpf',
    url: '#'
  }
]
