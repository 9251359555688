import { HighlightText, HighlightTitle } from '@/components'

const Consultation = ({ details }) => {
  return (
    <>
      {details.map(({ text, icon = 'equifax-check' }, index) => (
        <HighlightTitle
          size="medium"
          icon={icon}
          text={text}
          key={`consultation-details-${index}`}
        />
      ))}
    </>
  )
}

const Interconnect = ({ details }) => {
  return (
    <>
      {details.map(({ title, text }, index) => (
        <HighlightText
          iconSize="small"
          icon="equifax-check-circle"
          title={title}
          text={text}
          key={`interconnect-details-${index}`}
        />
      ))}
    </>
  )
}

const Details = { Consultation, Interconnect }

export { Details }
