'use client'

import Image from 'next/image'

import { useWhatsapp } from '@/hooks/useWhatsapp'

import WhatsappImage from '/public/icons/whatsapp-image.svg'

import { Button, If, Loader } from '..'

import { useConditionalRendering } from '@/hooks/useConditionalRendering'

export const ButtonWhatsapp = ({
  id,
  customClass,
  variant,
  children,
  disabled,
  label,
  onClickWhatsapp
}) => {
  const { isLoading, onClickWhatsappButton } = useWhatsapp({
    onClickWhatsapp
  })

  const { shouldRenderUnaffiliate } = useConditionalRendering()

  return (
    <If condition={shouldRenderUnaffiliate}>
      <Button
        id={id}
        variant={variant}
        disabled={disabled}
        className={customClass}
        aria-label={label}
        onClick={onClickWhatsappButton}
      >
        <If
          condition={isLoading}
          renderIf={<Loader />}
          renderElse={
            <>
              <Image
                priority
                width={24}
                height={24}
                src={WhatsappImage}
                alt="Whatsapp logo"
              />
              {children}
            </>
          }
        />
      </Button>
    </If>
  )
}
