'use client'

import { createContext, useState, useContext, useEffect } from 'react'

const ProposalContext = createContext({})

const useProposalContext = () => {
  const context = useContext(ProposalContext)

  if (!context) throw new Error('You need to define LeadProvider on top level')

  return context
}

const ProposalDataProvider = ({ children }) => {
  const [previousStep, setPreviousStep] = useState('softlead')
  const [leadData, setLeadData] = useState({})
  const [toastData, setToastData] = useState()

  const [isPaywallOpen, setIsPaywallOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isContactButtonVisible, setIsContactButtonVisible] = useState(false)

  useEffect(() => {
    if (isLoading && toastData?.isVisible) removeToast()
  }, [isLoading, toastData])

  const removeToast = () => {
    setToastData((prev) => ({ ...prev, isVisible: false }))
  }

  const updateToast = (data) => {
    setToastData({
      ...data,
      isVisible: true,
      onClickAction: removeToast
    })

    setTimeout(() => {
      removeToast()
    }, 5000)
  }

  return (
    <ProposalContext.Provider
      value={{
        leadData,
        previousStep,
        toastData,
        isLoading,
        isContactButtonVisible,
        isPaywallOpen,
        setLeadData,
        setToastData,
        setIsLoading,
        setIsContactButtonVisible,
        setIsPaywallOpen,
        setPreviousStep,
        updateToast
      }}
    >
      {children}
    </ProposalContext.Provider>
  )
}

export { ProposalDataProvider, useProposalContext }
