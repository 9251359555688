import { useEffect, useState } from 'react'

import isClient from '@equifax-ui/utils/browser/isClient'

import { SOURCE_URL_LABEL } from '@/constants/api'

export const useConditionalRendering = () => {
  const [shouldRenderUnaffiliate, setShouldRenderUnaffiliate] = useState(false)

  const sourceURL =
    (isClient() && JSON.parse(sessionStorage.getItem(SOURCE_URL_LABEL))) || '/'

  const isSourceUrlReady = sourceURL !== '/'

  useEffect(() => {
    if (isSourceUrlReady) {
      const shouldRenderUnaffiliate = !sourceURL.includes('r2n')

      setShouldRenderUnaffiliate(shouldRenderUnaffiliate)
    }
  }, [isSourceUrlReady])

  return { isSourceUrlReady, shouldRenderUnaffiliate }
}
