import { useState } from 'react'

export const usePlan = ({ plan, action, setExpandedPlans, onClickCard }) => {
  const [loadingButtonID, setLoadingButtonID] = useState()

  const isNotCustom = plan.id !== 'custom'

  const isOrganic = NEXT_PUBLIC_BUILD_TYPE === 'organic' // eslint-disable-line no-undef
  const planBaseRoute = isOrganic
    ? '/planos/formulario/dados-basicos/'
    : '/formulario/dados-basicos/'

  const onClickAction = () => {
    if (action?.onClick) {
      action.onClick()
      return
    }

    onExpandDetails()
  }

  const onExpandDetails = () => {
    setExpandedPlans((prev) => ({ ...prev, [plan.id]: !prev[plan.id] }))
  }

  const onSelectPlan = () => {
    if (plan.id !== 'custom') {
      onClickCard && onClickCard(plan.id)
    }
  }

  return {
    planBaseRoute,
    loadingButtonID,
    isNotCustom,
    onClickAction,
    onSelectPlan,
    setLoadingButtonID
  }
}
